<div id="shared-footer">
    <div class="content">
        <div class="top">
            <div class="right">
                <h3>Our Books</h3>
                <ul>

                </ul>
            </div>
            <div class="left">
                <h3>Follow us</h3>
                <ul>
                    <li>
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=61550784835984">
                            Facebook
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom">
            <div>Copyright &copy; 2023 All rights reserved.</div>
        </div>
    </div>
</div>

<div class="signin-form">
    <div class="content">
        <h1>Signin</h1>
        <form [formGroup]="signinForm">
            <fieldset>
                <input type="text" placeholder="Email" formControlName="email" />
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                    <div class="error" *ngIf="email?.errors?.['required']">
                        Email is required.
                    </div>
                    <div class="error" *ngIf="email?.errors?.['email']">
                        Invalid email.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <input [type]="getSigninPasswordType()" placeholder="Password" formControlName="password" />
                <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                    <div class="error" *ngIf="password?.errors?.['required']">
                        Password is required.
                    </div>
                    <div class="error" *ngIf="password?.errors?.['pattern']">
                        The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <mat-checkbox (change)="showPasswordTypeToggle()">
                    show password
                </mat-checkbox>
            </fieldset>
            <button
                [disabled]="getSigninButtonLoaderState()"
                (click)="signInButtonClick()">
            <div *ngIf="
                getSigninButtonLoaderState();
                then showSigninButtonLoader;
                else hideSigninButtonLoader">
            </div>
            <ng-template #showSigninButtonLoader>
                <mat-spinner [diameter]="20"></mat-spinner>
            </ng-template>
            <ng-template #hideSigninButtonLoader>
                Signin
            </ng-template>
        </button>
        </form>
    </div>
</div>

import { Component } from "@angular/core";
import { ContactsService } from "src/app/services/contact/contacts.service";
import { Contact } from "src/app/types/contact.type";
import { ListContactsScrollRequest } from "src/app/types/list-contacts-scroll-request.type";

@Component({
    selector: "app-contacts-list",
    templateUrl: "./contacts-list.component.html",
    styleUrls: ["./contacts-list.component.scss"]
})

export class ContactsListComponent {
    private tableLoaderIsActive: boolean = true;

    private hideTableLoader() {
        this.tableLoaderIsActive = false
    }

    public contacts: Contact[] = [];

    public constructor(
        private contactsService: ContactsService
    ) { }

    public async ngOnInit() {
        const params: ListContactsScrollRequest = {
            limit: 100
        }

        this.contacts = (await this.contactsService.list(params)).data;

        this.hideTableLoader();
    }

    public getTableLoaderState(): boolean {
        return this.tableLoaderIsActive;
    }
}

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { AuthModule } from "./auth/auth.module";
import { ContactsModule } from "./contacts/contacts.module";
import { BooksModule } from "./books/books.module";
import { BundlesModule } from "./bundles/bundles.module";
import { AccountComponent } from "./account/account.component";
import { PromotionsComponent } from "./promotions/promotions.component";
import { UsersComponent } from "./users/users.component";
import { PromotionsModule } from "./promotions/promotions.module";
import { UsersModule } from "./users/users.module";
import { AccountModule } from "./account/account.module";

@NgModule({
    declarations: [
        AppComponent,
        AccountComponent,
        PromotionsComponent,
        UsersComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        AuthModule,
        ContactsModule,
        BooksModule,
        BundlesModule,
        PromotionsModule,
        UsersModule,
        AccountModule
    ],
    providers: [],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }

import { Injectable } from "@angular/core";
import { BookBaseService } from "./book-base.service";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";
import { ListBooksScrollRequest } from "src/app/types/list-books-scroll-request.type";

@Injectable({
    providedIn: "root"
})

export class BooksService extends BookBaseService {
    public async list(params: ListBooksScrollRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list", params, true)
    }
}

<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Books > Create</h1>
        </div>
        <form [formGroup]="createBookForm" class="form">
            <h2>Base information</h2>
            <div formGroupName="baseInformations">
                <fieldset>
                    <label>Title</label>
                    <input type="text" formControlName="title" />
                </fieldset>
                <fieldset>
                    <label>Slug</label>
                    <input type="text" formControlName="slug" />
                </fieldset>
                <fieldset>
                    <label>Description</label>
                    <textarea formControlName="description"></textarea>
                </fieldset>
            </div>
            <h2>Search engine optimization</h2>
            <div formGroupName="seo">
                <fieldset>
                    <label>Title</label>
                    <input type="text" formControlName="title" />
                </fieldset>
                <fieldset>
                    <label>Keywords</label>
                    <input type="text" formControlName="keywords" />
                </fieldset>
                <fieldset>
                    <label>Description</label>
                    <textarea formControlName="description"></textarea>
                </fieldset>
            </div>
            <h2>Cover</h2>
            <div formGroupName="cover" class="image-container">
                <div class="left">
                    <div *ngIf="getCoverTempBase64(); then showCoverImage else showCoverDiv"></div>
                    <ng-template #showCoverImage>
                        <img (click)="coverImageClick.click()" class="cover-preview" [src]="getCoverTempBase64()" />
                    </ng-template>
                    <ng-template #showCoverDiv>
                        <img (click)="coverImageClick.click()" class="cover-preview" src="assets/img/cover-preview.png" />
                    </ng-template>
                </div>
                <div class="right">
                    <fieldset>
                        <label>Cover title</label>
                        <input type="text" formControlName="title" />
                    </fieldset>
                    <fieldset>
                        <label>Cover alt</label>
                        <input type="text" formControlName="alt" />
                    </fieldset>
                    <fieldset>
                        <input class="display-none" type="file" (change)="coverImageUploadChange($event.target)" #coverImageClick/>
                    </fieldset>
                </div>
            </div>
            <h2>Internals preview <button (click)="addPreviewClick()">Add preview</button></h2>
            <div formArrayName="internalsPreview">
                <div *ngFor="let preview of internalsPreview.controls; let i = index">
                    <div [formGroupName]="i">
                        <div class="image-container">
                            <div class="left">
                                <div *ngIf="getInternalsPreviewTempBase64(i); then showInternalsPreviewImage else showInternalsPreviewDiv"></div>
                                <ng-template #showInternalsPreviewImage>
                                    <img (click)="internalsPreviewClick.click()" class="internal-preview" [src]="getInternalsPreviewTempBase64(i)"/>
                                </ng-template>
                                <ng-template #showInternalsPreviewDiv>
                                    <img (click)="internalsPreviewClick.click()" class="internal-preview" src="assets/img/internal-preview.jpg"/>
                                </ng-template>
                            </div>
                            <div class="right">
                                <fieldset>
                                    <label>Internal preview {{ i + 1 }} title</label>
                                    <input type="text" formControlName="title" />
                                </fieldset>
                                <fieldset>
                                    <label>Internal preview {{ i + 1 }} alt</label>
                                    <input type="text" formControlName="alt" />
                                </fieldset>
                                <fieldset>
                                    <input class="display-none" type="file" (change)="internalsPreviewUploadChange($event.target, i)" #internalsPreviewClick />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <fieldset>
                <label>Purchase link</label>
                <div class="input-test-link">
                    <input type="text" formControlName="link" />
                    <a class="test-link" target="_blank" href="{{ link?.value }}">Test</a>
                </div>
                <div *ngIf="link?.invalid && (link?.dirty || link?.touched)">
                    <div class="error" *ngIf="link?.errors?.['required']">
                        Link is required.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <label>Alternative link</label>
                <div class="input-test-link">
                    <input type="text" formControlName="alternativeLink" />
                    <a class="test-link" target="_blank" href="{{ alternativeLink?.value }}">Test</a>
                </div>
            </fieldset>
            <fieldset>
                <label>Bundle link</label>
                <div class="input-test-link">
                    <input type="text" formControlName="bundleLink" />
                    <a class="test-link" target="_blank" href="{{ bundleLink?.value }}">Test</a>
                </div>
            </fieldset>
            <fieldset>
                <label>Status</label>
                <select formControlName="status">
                    <option *ngFor="let status of bookStatus" value="{{ status }}">{{ status }}</option>
                </select>
            </fieldset>
            <div formArrayName="settings">
                <fieldset>
                    <label>Featured</label>
                    <input type="checkbox" formControlName="featured">Show on the home page of the site
                </fieldset>
            </div>
            <div class="button-container">
                <button class="btn" [disabled]="getCreateButtonLoaderState()" (click)="createBookButtonClick()">
                    <div *ngIf="getCreateButtonLoaderState(); then showButtonLoader; else hideButtonLoader"></div>
                    <ng-template #showButtonLoader>
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-template>
                    <ng-template #hideButtonLoader>
                        Create
                    </ng-template>
                </button>
            </div>
        </form>
    </div>
</div>
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { SharedNavbarComponent } from "./shared-navbar/shared-navbar.component";
import { SharedFooterComponent } from "./shared-footer/shared-footer.component";
import { SharedLoaderComponent } from "./shared-loader/shared-loader.component";
import { DialogsErrorComponent } from '../dialogs/dialogs-error/dialogs-error.component';
import { DialogsConfirmActionComponent } from "../dialogs/dialogs-confirm-action/dialogs-confirm-action.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        SharedNavbarComponent,
        SharedFooterComponent,
        SharedLoaderComponent,
        DialogsErrorComponent,
        DialogsConfirmActionComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserModule,
        RouterModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        SharedNavbarComponent,
        SharedFooterComponent,
        SharedLoaderComponent,
        DialogsErrorComponent,
        DialogsConfirmActionComponent
    ]
})

export class SharedModule { }
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UsersComponent } from "./users.component";
import { UsersListComponent } from "./users-list/users-list.component";
import { AuthGuard } from "../guards/auth.guard";

const routes: Routes = [
    {
        path: "users",
        component: UsersComponent,
        children: [
            {
                path: "",
                component: UsersListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "list",
                component: UsersListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UsersRoutingModule { }

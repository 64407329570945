import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogsConfirmActionComponent } from "src/app/dialogs/dialogs-confirm-action/dialogs-confirm-action.component";
import { BookService } from "src/app/services/book/book.service";
import { FormBookService } from "src/app/services/form/form-book.service";
import { PromotionService } from "src/app/services/promotion/promotion.service";
import { UtilsDialogsService } from "src/app/services/utils/utils-dialogs.service";
import { Book } from "src/app/types/book.type";
import { UpdateBookRequest } from "src/app/types/update-book-request.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-books-edit",
    templateUrl: "./books-edit.component.html",
    styleUrls: ["./books-edit.component.scss"]
})

export class BooksEditComponent {
    private updateLoaderButtonIsActive: boolean = false;
    private deleteLoaderButtonIsActive: boolean = false;
    private promoteLoaderButtonIsActive: boolean = false;

    private showUpdateButtonLoader() {
        this.updateLoaderButtonIsActive = true;
    }

    private showDeleteButtonLoader() {
        this.deleteLoaderButtonIsActive = true;
    }

    private showPromoteButtonLoader() {
        this.promoteLoaderButtonIsActive = true;
    }

    private hideUpdateButtonLoader() {
        this.updateLoaderButtonIsActive = false;
    }

    private initCoverImage() {
        const xml = new XMLHttpRequest()

        xml.open("GET", "https://" + this.bucketName + ".s3.amazonaws.com/" + this.book.cover.src);

        xml.responseType = "blob";

        xml.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");

        xml.onload = () => {
            const blob = xml.response;

            const reader = new FileReader();

            reader.onloadend = (image: any) => {
                this.cover?.patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(blob)
        }

        xml.send()
    }

    private initInternalsPreviewImage(index: number) {
        const xml = new XMLHttpRequest()

        xml.open("GET", "https://" + this.bucketName + ".s3.amazonaws.com/" + this.book.internalsPreview[index].src);

        xml.responseType = "blob";

        xml.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");

        xml.onload = () => {
            const blob = xml.response;

            const reader = new FileReader();

            reader.onloadend = (image: any) => {
                this.internalsPreview.controls[index].patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(blob)
        }

        xml.send()
    }

    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public editBookForm: FormGroup;
    public id: string = "";
    public book!: Book;
    public coverData: any;
    public obj: any = {};

    public constructor(
        private route: ActivatedRoute,
        private formBookService: FormBookService,
        private bookService: BookService,
        private promoteService: PromotionService,
        private dialog: MatDialog,
        private router: Router
    ) {
        this.editBookForm = this.formBookService.edit;
    }

    public async ngOnInit() {
        this.id = this.route.snapshot.paramMap.get("id") || "";

        this.book = await this.bookService.read(this.id)

        this.initCoverImage()

        this.editBookForm.reset({
            baseInformations: this.book.baseInformations,
            seo: this.book.seo,
            cover: this.book.cover,
            link: this.book.link,
            alternativeLink: this.book.alternativeLink,
            bundleLink: this.book.bundleLink,
            status: this.book.status,
            settings: this.book.settings
        })

        this.internalsPreview.clear()

        for (let i = 0; i < this.book.internalsPreview.length; i++) {
            const image: FormGroup = new FormBuilder().group({
                title: [ this.book.internalsPreview[i].title, [ Validators.required ] ],
                alt: [ this.book.internalsPreview[i].alt, [ Validators.required ] ],
                src: null,
                tempBase64: [ null, [ Validators.required ] ]
            })

            this.internalsPreview.push(image)

            this.initInternalsPreviewImage(i)
        }
    }

    public get link() {
        return this.editBookForm.get("link")
    }

    public get alternativeLink() {
        return this.editBookForm.get("alternativeLink")
    }

    public get bundleLink() {
        return this.editBookForm.get("bundleLink")
    }

    public get cover() {
        return this.editBookForm.get("cover")
    }

    public get seo() {
        return this.editBookForm.get("seo")
    }

    public get baseInformations() {
        return this.editBookForm.get("baseInformations")
    }

    public get status() {
        return this.editBookForm.get("status")
    }

    public get settings() {
        return this.editBookForm.get("settings")
    }

    public get internalsPreview(): FormArray {
        return this.editBookForm.get("internalsPreview") as FormArray
    }

    public getCoverTempBase64() {
        return (this.editBookForm.get("cover")?.value).tempBase64
    }

    public getInternalsPreviewTempBase64(index: number) {
        return (this.editBookForm.get("internalsPreview")?.value)[index].tempBase64
    }

    public addPreviewClick() {
        const image: FormGroup = new FormBuilder().group({
            title: [ null, [ Validators.required ] ],
            alt: [ null, [ Validators.required ] ],
            src: null,
            tempBase64: [ null, [ Validators.required ] ]
        })

        this.internalsPreview.push(image)
    }

    public coverImageUploadChange(input: any) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (image: any) => {
                this.cover?.patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public internalsPreviewUploadChange(input: any, index: number) {
        if (input.files && input.files[0]) {
            const reader = new FileReader()

            reader.onload = (image: any) => {
                this.internalsPreview.controls[index].patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public getUpdateButtonLoaderState(): boolean {
        return this.updateLoaderButtonIsActive;
    }

    public getDeleteButtonLoaderState(): boolean {
        return this.deleteLoaderButtonIsActive;
    }

    public getPromoteButtonLoaderState(): boolean {
        return this.promoteLoaderButtonIsActive;
    }

    public async updateBookButtonClick() {
        if ( ! this.formBookService.validateForm(this.editBookForm)) {
            return
        }

        this.showUpdateButtonLoader()

        const book: UpdateBookRequest = {
            id: this.id,
            baseInformations: this.baseInformations?.value,
            cover: this.cover?.value,
            seo: this.seo?.value,
            internalsPreview: this.internalsPreview?.value,
            link: this.link?.value,
            alternativeLink: this.alternativeLink?.value,
            bundleLink: this.bundleLink?.value,
            status: this.status?.value,
            settings: this.settings?.value
        }

        try {
            const updateBook = await this.bookService.update(book);

            this.book = updateBook;

            this.hideUpdateButtonLoader();
        } catch(error) {
            console.log(error);
        }
    }

    public async promoteBookButtonClick() {
        this.showPromoteButtonLoader()

        await this.promoteService.create({
            settings: {
                book: true,
                bundle: false
            },
            bookId: this.book.id,
            title: this.book.baseInformations.title
        })

        this.router.navigate(["/promotions"])
    }

    public async deleteBookButtonClick() {
        this.dialog.open(DialogsConfirmActionComponent, {
            data: {
                title: "Delete book",
                message: "Delete this book?"
            },
            width: "512px"
        }).afterClosed().subscribe(async (result) => {
            if (result.data === "confirm") {
                this.showDeleteButtonLoader()

                await this.bookService.delete({
                    id: this.book.id,
                    slug: this.book.baseInformations.slug,
                    settings: this.book.settings
                })

                this.router.navigate(["/books"])
            }
        })
    }

    public removeInternalPreviewButtonClick(index: number) {
        this.internalsPreview.removeAt(index)
    }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "",
        redirectTo: "auth/signin",
        pathMatch: "full"
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            scrollPositionRestoration: "enabled"
        })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { ContactsRoutingModule } from "./contacts-routing.module";
import { ContactsComponent } from "./contacts.component";
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { ContactsCreateComponent } from './contacts-create/contacts-create.component';
import { ContactsEditComponent } from './contacts-edit/contacts-edit.component';
import { ContactsReadComponent } from './contacts-read/contacts-read.component';

@NgModule({
    declarations: [
        ContactsComponent,
        ContactsListComponent,
        ContactsCreateComponent,
        ContactsEditComponent,
        ContactsReadComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ContactsRoutingModule
    ]
})

export class ContactsModule { }

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-dialogs-confirm-action",
    templateUrl: "./dialogs-confirm-action.component.html",
    styleUrls: ["./dialogs-confirm-action.component.css"]
})

export class DialogsConfirmActionComponent {
    public title: string = "";
    public message: string = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,

        public dialog: MatDialogRef<DialogsConfirmActionComponent>,
    ) {}

    public async ngOnInit(): Promise<void> {
        this.title = this.data.title;
        this.message = this.data.message;
    }

    public confirm() {
        this.dialog.close({
            data: "confirm"
        })
    }

    public reject() {
        this.dialog.close({
            data: "reject"
        })
    }
}

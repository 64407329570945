import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";
import { BundlesComponent } from "./bundles.component";
import { BundlesListComponent } from "./bundles-list/bundles-list.component";
import { BundlesCreateComponent } from "./bundles-create/bundles-create.component";
import { BundlesEditComponent } from "./bundles-edit/bundles-edit.component";

const routes: Routes = [
    {
        path: "bundles",
        component: BundlesComponent,
        children: [
            {
                path: "",
                component: BundlesListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "list",
                component: BundlesListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "create",
                component: BundlesCreateComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "edit/:id",
                component: BundlesEditComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BundlesRoutingModule { }

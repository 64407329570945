import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormBaseService } from "./form-base.service";

export const AUTH_PASS_PATTERN: string = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$';

@Injectable({
    providedIn: "root"
})

export class FormAuthService extends FormBaseService {
    public signin: FormGroup = new FormBuilder().group({
        email: [
            null,
            [
                Validators.required,
                Validators.email
            ]
        ],
        password: [
            null,
            [
                Validators.required,
                Validators.pattern(AUTH_PASS_PATTERN)
            ]
        ]
    })

    public newPassword: FormGroup = new FormBuilder().group({
        currentPassword: [
            null,
            [
                Validators.required,
                Validators.pattern(AUTH_PASS_PATTERN)
            ]
        ],
        newPassword: [
            null,
            [
                Validators.required,
                Validators.pattern(AUTH_PASS_PATTERN)
            ]
        ],
        confirmPassword: [
            null,
            [
                Validators.required,
                Validators.pattern(AUTH_PASS_PATTERN)
            ]
        ]
    })
}

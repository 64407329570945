import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { BundlesRoutingModule } from "./bundles-routing.module";
import { BundlesComponent } from "./bundles.component";
import { BundlesListComponent } from './bundles-list/bundles-list.component';
import { BundlesCreateComponent } from './bundles-create/bundles-create.component';
import { BundlesEditComponent } from './bundles-edit/bundles-edit.component';

@NgModule({
    declarations: [
        BundlesComponent,
        BundlesListComponent,
        BundlesCreateComponent,
        BundlesEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        BundlesRoutingModule
    ]
})

export class BundlesModule { }

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { BooksRoutingModule } from "./books-routing.module";
import { BooksComponent } from "./books.component";
import { BooksListComponent } from './books-list/books-list.component';
import { BooksEditComponent } from './books-edit/books-edit.component';
import { BooksCreateComponent } from './books-create/books-create.component';

@NgModule({
    declarations: [
        BooksComponent,
        BooksListComponent,
        BooksEditComponent,
        BooksCreateComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        BooksRoutingModule
    ]
})

export class BooksModule { }

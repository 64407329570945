import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogsConfirmActionComponent } from "src/app/dialogs/dialogs-confirm-action/dialogs-confirm-action.component";
import { PromotionsService } from "src/app/services/promotion/promotions.service";
import { ListPromotionsScrollRequest } from "src/app/types/list-promotions-scroll-request.type";
import { Promotion } from "src/app/types/promotion.type";

@Component({
    selector: "app-promotions-list",
    templateUrl: "./promotions-list.component.html",
    styleUrls: ["./promotions-list.component.scss"]
})

export class PromotionsListComponent {
    private tableLoaderIsActive: boolean = true;

    private hideTableLoader() {
        this.tableLoaderIsActive = false
    }

    public promotions: Promotion[] = [];

    public constructor(
        private promotionsService: PromotionsService,
        private dialog: MatDialog
    ) { }

    public async ngOnInit() {
        const params: ListPromotionsScrollRequest = {
            limit: 100
        }

        this.promotions = (await this.promotionsService.list(params)).data;

        this.hideTableLoader();
    }

    public getTableLoaderState(): boolean {
        return this.tableLoaderIsActive;
    }

    public async testBookPromotionButtonClick() {
        this.dialog.open(DialogsConfirmActionComponent, {
            data: {
                title: "Test book promotion",
                message: "Test book promotion"
            },
            width: "512px"
        }).afterClosed().subscribe(async (result: any) => {
            if (result.data === "confirm") {
                await this.promotionsService.testBook()
            }
        })
    }

    public async testBundlePromotionButtonClick() {
        this.dialog.open(DialogsConfirmActionComponent, {
            data: {
                title: "Test bundle promotion",
                message: "Test bundle promotion"
            },
            width: "512px"
        }).afterClosed().subscribe(async (result: any) => {
            if (result.data === "confirm") {
                await this.promotionsService.testBundle()
            }
        })
    }
}

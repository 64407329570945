<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Books > List</h1>
            <button routerLink="/books/create"><mat-icon>add</mat-icon>Create book</button>
        </div>
        <div *ngIf="getTableLoaderState(); then showBooksLoader else hideBooksLoader"></div>
        <ng-template #showBooksLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #hideBooksLoader>
            <div *ngIf="books.length > 0; then showBooks else booksNotFound"></div>
            <ng-template #showBooks>
                <table class="table">
                    <tr>
                        <th><div>Title</div></th>
                        <th><div>Creation date</div></th>
                        <th><div>Featured</div></th>
                        <th><div>Status</div></th>
                        <th><div></div></th>
                    </tr>
                    <tr *ngFor="let book of books">
                        <td><strong>{{ book.baseInformations.title }}</strong></td>
                        <td>{{ book.createdAt | date }}</td>
                        <td>{{ book.settings.featured }}</td>
                        <td>
                            <div class="table__status--{{ book.status | lowercase }}">
                                {{ book.status }}
                            </div>
                        </td>
                        <td routerLink="/books/edit/{{ book.id }}">
                            <mat-icon>more_horiz</mat-icon>
                        </td>
                    </tr>
                </table>
            </ng-template>
            <ng-template #booksNotFound>
                <div class="not-found-container">Books not found</div>
            </ng-template>
        </ng-template>
    </div>
</div>
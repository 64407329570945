import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormBaseService } from "./form-base.service";
import { BookStatus } from "../../types/book-status.type";

@Injectable({
    providedIn: "root"
})

export class FormBookService extends FormBaseService {
    private baseInformations: FormGroup = new FormBuilder().group({
        title: [ null, [ Validators.required ] ],
        slug: [ null, [ Validators.required ] ],
        description: [ null, [ Validators.required ] ]
    })

    private seo: FormGroup = new FormBuilder().group({
        title: [ null, [ Validators.required ] ],
        keywords: [ null, [ Validators.required ] ],
        description: [ null, [ Validators.required ] ]
    })

    public image: FormGroup = new FormBuilder().group({
        title: [ null, [ Validators.required ] ],
        alt: [ null, [ Validators.required ] ],
        src: null,
        tempBase64: [ null, [ Validators.required ] ]
    })

    public settings: FormGroup = new FormBuilder().group({
        featured: [ false ]
    })

    public create: FormGroup = new FormBuilder().group({
        baseInformations: this.baseInformations,
        cover: this.image,
        seo: this.seo,
        internalsPreview: new FormArray([]),
        link: [ null, [ Validators.required ] ],
        alternativeLink: [null],
        bundleLink: [null],
        status: [ BookStatus.Draft, [ Validators.required ] ],
        settings: this.settings
    })

    public edit: FormGroup = new FormBuilder().group({
        baseInformations: this.baseInformations,
        cover: this.image,
        seo: this.seo,
        internalsPreview: new FormArray([]),
        link: [ null, [ Validators.required ] ],
        alternativeLink: [null],
        bundleLink: [null],
        status: [ BookStatus.Draft, [ Validators.required ] ],
        settings: this.settings
    })
}

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogsErrorComponent } from "src/app/dialogs/dialogs-error/dialogs-error.component";
import { UtilsBaseService } from "./utils-base.service";
import { DialogsConfirmActionComponent } from "src/app/dialogs/dialogs-confirm-action/dialogs-confirm-action.component";

@Injectable({
    providedIn: "root"
})

export class UtilsDialogsService extends UtilsBaseService {
    public constructor(
        private dialog: MatDialog
    ) {
        super()
    }

    public showError(error: string) {
        this.dialog.open(DialogsErrorComponent, {
            data: {
                errorCode: error
            },
            width: "512px"
        })
    }

    public async showConfirm(title: string, message: string): Promise<any> {
        this.dialog.open(DialogsConfirmActionComponent, {
            data: {
                title: title,
                message: message
            },
            width: "512px"
        }).afterClosed().subscribe(result => {
            return Promise.resolve(result)
        })
    }
}
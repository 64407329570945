import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";
import { BooksComponent } from "./books.component";
import { BooksListComponent } from "./books-list/books-list.component";
import { BooksEditComponent } from "./books-edit/books-edit.component";
import { BooksCreateComponent } from "./books-create/books-create.component";

const routes: Routes = [
    {
        path: "books",
        component: BooksComponent,
        children: [
            {
                path: "",
                component: BooksListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "list",
                component: BooksListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "create",
                component: BooksCreateComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "edit/:id",
                component: BooksEditComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BooksRoutingModule { }

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SigninComponent } from "./signin/signin.component";
import { AuthComponent } from "./auth.component";
import { UnauthGuard } from "../guards/unauth.guard";
import { NewPasswordComponent } from "./new-password/new-password.component";

const routes: Routes = [
    {
        path: "auth",
        component: AuthComponent,
        children: [
            {
                path: "",
                component: SigninComponent,
                pathMatch: "full",
                canActivate: [ UnauthGuard ]
            }, {
                path: "signin",
                component: SigninComponent,
                pathMatch: "full",
                canActivate: [ UnauthGuard ]
            }, {
                path: "new-password",
                component: NewPasswordComponent,
                pathMatch: "full",
                canActivate: [ UnauthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AuthRoutingModule { }

<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Bundles > Edit > {{ id }}</h1>
            <button class="btn" [disabled]="getDeleteButtonLoaderState()" (click)="deleteBundleButtonClick()">
                <div *ngIf="getDeleteButtonLoaderState(); then showDeleteButtonLoader; else hideDeleteButtonLoader"></div>
                <ng-template #showDeleteButtonLoader>
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-template>
                <ng-template #hideDeleteButtonLoader>
                    <mat-icon>delete_outline</mat-icon>
                    Delete
                </ng-template>
            </button>
        </div>
        <div *ngIf="getPageLoaderState(); then showLoader else showPage"></div>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #showPage>
            <form [formGroup]="editBundleForm" class="form">
                <h2>Base information</h2>
                <div formGroupName="baseInformations">
                    <fieldset>
                        <label>Title</label>
                        <input type="text" formControlName="title" />
                    </fieldset>
                    <fieldset>
                        <label>Slug</label>
                        <input type="text" formControlName="slug" />
                    </fieldset>
                    <fieldset>
                        <label>Description</label>
                        <textarea formControlName="description"></textarea>
                    </fieldset>
                </div>
                <h2>Search engine optimization</h2>
                <div formGroupName="seo">
                    <fieldset>
                        <label>Title</label>
                        <input type="text" formControlName="title" />
                    </fieldset>
                    <fieldset>
                        <label>Keywords</label>
                        <input type="text" formControlName="keywords" />
                    </fieldset>
                    <fieldset>
                        <label>Description</label>
                        <textarea formControlName="description"></textarea>
                    </fieldset>
                </div>
                <h2>Cover</h2>
                <div formGroupName="cover" class="image-container">
                    <div class="left">
                        <div *ngIf="getCoverTempBase64(); then showCoverImage else showCoverDiv"></div>
                        <ng-template #showCoverImage>
                            <img (click)="coverImageClick.click()" class="internal-preview" [src]="getCoverTempBase64()" />
                        </ng-template>
                        <ng-template #showCoverDiv>
                            <img (click)="coverImageClick.click()" class="internal-preview" src="assets/img/internal-preview.jpg" />
                        </ng-template>
                    </div>
                    <div class="right">
                        <fieldset>
                            <label>Cover title</label>
                            <input type="text" formControlName="title" />
                        </fieldset>
                        <fieldset>
                            <label>Cover alt</label>
                            <input type="text" formControlName="alt" />
                        </fieldset>
                        <fieldset>
                            <input class="display-none" type="file" (change)="coverImageUploadChange($event.target)" #coverImageClick />
                        </fieldset>
                    </div>
                </div>
                <h2>Internals preview <button (click)="addPreviewClick()">Add preview</button></h2>
                <div formArrayName="internalsPreview">
                    <div *ngFor="let preview of internalsPreview.controls; let i = index">
                        <div [formGroupName]="i">
                            <div class="image-container">
                                <div class="left">
                                    <div *ngIf="getInternalsPreviewTempBase64(i); then showInternalsPreviewImage else showInternalsPreviewDiv"></div>
                                    <ng-template #showInternalsPreviewImage>
                                        <img (click)="internalsPreviewClick.click()" class="internal-preview" [src]="getInternalsPreviewTempBase64(i)"/>
                                    </ng-template>
                                    <ng-template #showInternalsPreviewDiv>
                                        <img (click)="internalsPreviewClick.click()" class="internal-preview" src="assets/img/internal-preview.jpg"/>
                                    </ng-template>
                                </div>
                                <div class="right">
                                    <fieldset>
                                        <label>Internal preview {{ i + 1 }} title</label>
                                        <input type="text" formControlName="title" />
                                    </fieldset>
                                    <fieldset>
                                        <label>Internal preview {{ i + 1 }} alt</label>
                                        <input type="text" formControlName="alt" />
                                    </fieldset>
                                    <fieldset>
                                        <input class="display-none" type="file" (change)="internalsPreviewUploadChange($event.target, i)" #internalsPreviewClick />
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <fieldset>
                    <label>Price</label>
                    <input type="number" formControlName="price" />
                </fieldset>
                <fieldset>
                    <label>Download link</label>
                    <div class="input-test-link">
                        <input type="text" formControlName="downloadLink" />
                        <a class="test-link" target="_blank" href="{{ downloadLink?.value }}">Test</a>
                    </div>
                </fieldset>
                <fieldset>
                    <label>Status</label>
                    <select formControlName="status">
                        <option value="DRAFT">DRAFT</option>
                        <option value="ENABLED">ENABLED</option>
                        <option value="DISABLED">DISABLED</option>
                    </select>
                </fieldset>
                <div formArrayName="settings">
                    <fieldset>
                        <label>Featured</label>
                        <input type="checkbox" formControlName="featured">Show on the home page of the site
                    </fieldset>
                </div>
                <h2>Stripe data</h2>
                <div formGroupName="stripeData">
                    <fieldset>
                        <label>Price ID</label>
                        <input class="disabled" type="text" formControlName="priceId" readonly>
                    </fieldset>
                    <fieldset>
                        <label>Product ID</label>
                        <input class="disabled" type="text" formControlName="productId" readonly>
                    </fieldset>
                    <fieldset>
                        <label>Payment link ID</label>
                        <input class="disabled" type="text" formControlName="paymentLinkId" readonly>
                    </fieldset>
                    <fieldset>
                        <label>Payment link URL</label>
                        <input class="disabled" type="text" formControlName="paymentLinkUrl" readonly>
                    </fieldset>
                </div>
                <div class="button-container">
                    <button class="btn" [disabled]="getEditButtonLoaderState()" (click)="editBundleButtonClick()">
                        <div *ngIf="getEditButtonLoaderState(); then showButtonLoader; else hideButtonLoader"></div>
                        <ng-template #showButtonLoader>
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </ng-template>
                        <ng-template #hideButtonLoader>
                            Update
                        </ng-template>
                    </button>
                    <button class="btn" [disabled]="getPromoteButtonLoaderState()" (click)="promoteBookButtonClick()">
                        <div *ngIf="getPromoteButtonLoaderState(); then showPromoteButtonLoader; else hidePromoteButtonLoader"></div>
                        <ng-template #showPromoteButtonLoader>
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </ng-template>
                        <ng-template #hidePromoteButtonLoader>
                            Promote
                        </ng-template>
                    </button>
                </div>
            </form>
        </ng-template>
    </div>
</div>

<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Promotions > Edit > {{ id }}</h1>
            <button class="btn" [disabled]="getDeleteButtonLoaderState()" (click)="deletePromotionButtonClick()">
                <div *ngIf="getDeleteButtonLoaderState(); then showDeleteButtonLoader; else hideDeleteButtonLoader"></div>
                <ng-template #showDeleteButtonLoader>
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-template>
                <ng-template #hideDeleteButtonLoader>
                    <mat-icon>delete_outline</mat-icon>
                    Delete
                </ng-template>
            </button>
        </div>
        <div *ngIf="getPageLoaderState(); then showLoader else showPage"></div>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #showPage>
            <form class="form">
                <h2>Base information</h2>
                <div>
                    <fieldset>
                        <label>Title</label>
                        <input class="disabled" type="text" value="{{ promotion.title }}" readonly>
                    </fieldset>
                    <fieldset>
                        <label>Bundle ID</label>
                        <input class="disabled" type="text" value="{{ promotion.bundleId }}" readonly>
                    </fieldset>
                    <fieldset>
                        <label>Book ID</label>
                        <input class="disabled" type="text" value="{{ promotion.bookId }}" readonly>
                    </fieldset>
                    <fieldset>
                        <label>Status</label>
                        <input class="disabled" type="text" value="{{ promotion.status }}" readonly>
                    </fieldset>
                </div>
            </form>
        </ng-template>
    </div>
</div>

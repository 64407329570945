import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FormAuthService } from "src/app/services/form/form-auth.service";
import { UtilsDialogsService } from "src/app/services/utils/utils-dialogs.service";

@Component({
    selector: "app-signin",
    templateUrl: "./signin.component.html",
    styleUrls: ["./signin.component.scss"]
})

export class SigninComponent {
    private signinButtonLoaderIsActive: boolean = false;
    private showSigninPassword: boolean = false;

    private showSigninButtonLoader() {
        this.signinButtonLoaderIsActive = true;
    }

    private hideSigninButtonLoader() {
        this.signinButtonLoaderIsActive = false;
    }

    public signinForm: FormGroup;

    public constructor(
        private formAuthService: FormAuthService,
        private auth: AuthService,
        private router: Router,
        private utilsDialogsService: UtilsDialogsService
    ) {
        this.signinForm = this.formAuthService.signin;
    }

    public ngOnInit() {
        this.signinForm.reset();
    }

    public get email() {
        return this.signinForm.get("email");
    }

    public get password() {
        return this.signinForm.get("password");
    }

    public getSigninButtonLoaderState(): boolean {
        return this.signinButtonLoaderIsActive;
    }

    public showPasswordTypeToggle() {
        this.showSigninPassword = !this.showSigninPassword;
    }

    public getSigninPasswordType(): "text" | "password" {
        if (this.showSigninPassword) {
            return "text";
        }

        return "password";
    }

    public async signInButtonClick() {
        if ( ! this.formAuthService.validateForm(this.signinForm)) {
            return;
        }

        this.showSigninButtonLoader();

        let email: string = this.email?.value;
        let password: string = this.password?.value;

        let data = await this.auth.signIn(email, password);

        if (data.response.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.hideSigninButtonLoader();

            localStorage.setItem("username", email);

            this.router.navigate(["auth/new-password"]);

            return;
        }

        if (data.response.code === "UserNotFoundException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("User does not exist.");

            return;
        }

        if (data.response.code === "NotAuthorizedException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("Incorrect username or password.");

            return;
        }

        this.router.navigate(["users"]);
    }
}

import { Injectable } from "@angular/core";
import { PromotionBaseService } from "./promotion-base.service";
import { Promotion } from "src/app/types/promotion.type";
import { CreatePromotionRequest } from "src/app/types/create-promotion-request.type";
import { DeletePromotionRequest } from "src/app/types/delete-promotion-request.type";

@Injectable({
    providedIn: "root"
})

export class PromotionService extends PromotionBaseService {
    public async read(id: string): Promise<Promotion> {
        return await this.get("/" + id, null, true)
    }

    public async create(params: CreatePromotionRequest): Promise<Promotion> {
        return await this.post("/", params, true);
    }

    public async delete(params: DeletePromotionRequest) {
        return await this.del("/", params, true)
    }
}

import { Injectable } from "@angular/core";
import { BundleBaseService } from "./bundle-base.service";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";
import { ListBundlesScrollRequest } from "src/app/types/list-bundles-scroll-request.type";

@Injectable({
    providedIn: "root"
})

export class BundlesService extends BundleBaseService {
    public async list(params: ListBundlesScrollRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list", params, true)
    }
}

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { API, Auth } from "aws-amplify";
import { environment } from "./environments/environment";

Auth.configure({
    Auth: {
        identityPoolId: environment.IDENTITY_POOL_ID,
        region: environment.USER_POOL_REGION,
        identityPoolRegion: environment.IDENTITY_POOL_REGION,
        userPoolId: environment.USER_POOL_ID,
        userPoolWebClientId: environment.USER_POOL_CLIENT_ID
    }
})

API.configure({
    endpoints: [
        {
            name: "books",
            endpoint: "https://" + environment.API_BASE_PATH,
            region: environment.IDENTITY_POOL_REGION
        }, {
            name: "contacts",
            endpoint: "https://" + environment.API_BASE_PATH,
            region: environment.IDENTITY_POOL_REGION
        }, {
            name: "bundles",
            endpoint: "https://" + environment.API_BASE_PATH,
            region: environment.IDENTITY_POOL_REGION
        }, {
            name: "promotions",
            endpoint: "https://" + environment.API_BASE_PATH,
            region: environment.IDENTITY_POOL_REGION
        }
    ]
})

platformBrowserDynamic().bootstrapModule(AppModule);
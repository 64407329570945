import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "app-shared-navbar",
    templateUrl: "./shared-navbar.component.html",
    styleUrls: ["./shared-navbar.component.scss"]
})

export class SharedNavbarComponent {
    public admin: any;

    public constructor(
        private authService: AuthService
    ) {}

    public async ngOnInit() {
        try {
            this.admin = await this.authService.getAuthenticatedUser();
        } catch(error) {
            console.log(error)
        }
    }

    public async signout() {
        await this.authService.signOut();
    }
}

import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { BookService } from "src/app/services/book/book.service";
import { BookStatus } from "src/app/types/book-status.type";
import { FormBookService } from "src/app/services/form/form-book.service";
import { CreateBookRequest } from "src/app/types/create-book-request.type";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-books-create",
    templateUrl: "./books-create.component.html",
    styleUrls: ["./books-create.component.scss"]
})

export class BooksCreateComponent {
    private createLoaderButtonIsActive: boolean = false;

    private showCreateButtonLoader() {
        this.createLoaderButtonIsActive = true;
    }

    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;
    public createBookForm: FormGroup;
    public bookStatus: string[] = Object.values(BookStatus);

    public ngOnInit() {
        this.createBookForm.reset({
            status: BookStatus.Draft
        })

        this.internalsPreview.clear()
    }

    public constructor(
        private formBookService: FormBookService,
        private bookService: BookService,
        private router: Router
    ) {
        this.createBookForm = this.formBookService.create
    }

    public get baseInformations() {
        return this.createBookForm.get("baseInformations")
    }

    public get link() {
        return this.createBookForm.get("link")
    }

    public get alternativeLink() {
        return this.createBookForm.get("alternativeLink")
    }

    public get bundleLink() {
        return this.createBookForm.get("bundleLink")
    }

    public get seo() {
        return this.createBookForm.get("seo")
    }

    public get cover() {
        return this.createBookForm.get("cover")
    }

    public get status() {
        return this.createBookForm.get("status")
    }

    public get settings() {
        return this.createBookForm.get("settings")
    }

    public get internalsPreview(): FormArray {
        return this.createBookForm.get("internalsPreview") as FormArray
    }

    public getCoverTempBase64() {
        return (this.createBookForm.get("cover")?.value).tempBase64
    }

    public getInternalsPreviewTempBase64(index: number) {
        return (this.createBookForm.get("internalsPreview")?.value)[index].tempBase64
    }

    public addPreviewClick() {
        const image: FormGroup = new FormBuilder().group({
            title: [ null, [ Validators.required ] ],
            alt: [ null, [ Validators.required ] ],
            src: null,
            tempBase64: [ null, [ Validators.required ] ]
        })

        this.internalsPreview.push(image)
    }

    public coverImageUploadChange(input: any) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (image: any) => {
                this.cover?.patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public internalsPreviewUploadChange(input: any, index: number) {
        if (input.files && input.files[0]) {
            const reader = new FileReader()

            reader.onload = (image: any) => {
                this.internalsPreview.controls[index].patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public getCreateButtonLoaderState(): boolean {
        return this.createLoaderButtonIsActive;
    }

    public async createBookButtonClick() {
        if ( ! this.formBookService.validateForm(this.createBookForm)) {
            return;
        }

        this.showCreateButtonLoader()

        const book: CreateBookRequest = {
            baseInformations: this.baseInformations?.value,
            seo: this.seo?.value,
            cover: this.cover?.value,
            internalsPreview: this.internalsPreview?.value,
            link: this.link?.value,
            alternativeLink: this.alternativeLink?.value,
            bundleLink: this.bundleLink?.value,
            status: this.status?.value,
            settings: this.settings?.value
        }

        try {
            await this.bookService.create(book);

            this.router.navigate(["/books/list"])
        } catch(error) {
            console.log(error);
        }
    }
}

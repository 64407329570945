<div class="new-password-form">
    <div class="content">
        <h1>New password</h1>
        <form [formGroup]="newPasswordForm">
            <fieldset>
                <input type="password" placeholder="Current password *" formControlName="currentPassword" />
                <div *ngIf="currentPassword?.invalid && (currentPassword?.dirty || currentPassword?.touched)">
                    <div class="error" *ngIf="currentPassword?.errors?.['required']">
                        Current password is required.
                    </div>
                    <div class="error" *ngIf="currentPassword?.errors?.['pattern']">
                        The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <input type="password" placeholder="New password *" formControlName="newPassword" />
                <div *ngIf="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)">
                    <div class="error" *ngIf="newPassword?.errors?.['required']">
                        New password is required.
                    </div>
                    <div class="error" *ngIf="newPassword?.errors?.['pattern']">
                        The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <input [type]="getNewPasswordPasswordType()" placeholder="Confirm password *" formControlName="confirmPassword" />
                <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
                    <div class="error" *ngIf="confirmPassword?.errors?.['required']">
                        Confirm password is required.
                    </div>
                    <div class="error" *ngIf="confirmPassword?.errors?.['pattern']">
                        The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <mat-checkbox (change)="showPasswordTypeToggle()">
                    show password
                </mat-checkbox>
            </fieldset>
            <button
                [disabled]="getNewPasswordButtonLoaderState()"
                (click)="newPasswordButtonClick()">
            <div *ngIf="
                getNewPasswordButtonLoaderState();
                then showNewPasswordButtonLoader;
                else hideNewPasswordButtonLoader">
            </div>
            <ng-template #showNewPasswordButtonLoader>
                <mat-spinner [diameter]="20"></mat-spinner>
            </ng-template>
            <ng-template #hideNewPasswordButtonLoader>
                Send
            </ng-template>
        </button>
        </form>
    </div>
</div>

import { Component } from "@angular/core";

@Component({
    selector: "app-shared-footer",
    templateUrl: "./shared-footer.component.html",
    styleUrls: ["./shared-footer.component.scss"]
})

export class SharedFooterComponent {
    public constructor() {}

    public ngOnInit() { }
}

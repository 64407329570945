<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Promotions > List</h1>
            <div class="button-container">
                <button (click)="testBookPromotionButtonClick()">Test book promotion</button>
                <button (click)="testBundlePromotionButtonClick()">Test bundle promotion</button>
            </div>
        </div>
        <div *ngIf="getTableLoaderState(); then showPromotionsLoader else hidePromotionsLoader"></div>
        <ng-template #showPromotionsLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #hidePromotionsLoader>
            <div *ngIf="promotions.length > 0; then showPromotions else promotionsNotFound"></div>
            <ng-template #showPromotions>
                <table class="table">
                    <tr>
                        <th><div>Creation date</div></th>
                        <th><div>Title</div></th>
                        <th><div>Emails send count</div></th>
                        <th><div>Status</div></th>
                        <th><div></div></th>
                    </tr>
                    <tr *ngFor="let promotion of promotions">
                        <td>{{ promotion.createdAt | date }}</td>
                        <td><strong>{{ promotion.title }}</strong></td>
                        <td>{{ promotion.report.emailsSendCount }}</td>
                        <td>
                            <div class="table__status--{{ promotion.status | lowercase }}">
                                {{ promotion.status }}
                            </div>
                        </td>
                        <td routerLink="/promotions/edit/{{ promotion.id }}">
                            <mat-icon>more_horiz</mat-icon>
                        </td>
                    </tr>
                </table>
            </ng-template>
            <ng-template #promotionsNotFound>
                <div class="not-found-container">Promotions not found</div>
            </ng-template>
        </ng-template>
    </div>
</div>

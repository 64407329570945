import { Injectable } from "@angular/core";
import { ContactBaseService } from "./contact-base.service";
import { ListContactsScrollRequest } from "src/app/types/list-contacts-scroll-request.type";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";

@Injectable({
    providedIn: "root"
})

export class ContactsService extends ContactBaseService {
    public async list(params: ListContactsScrollRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list", params, true);
    }
}

import { Injectable } from "@angular/core";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";
import { PromotionBaseService } from "./promotion-base.service";
import { ListPromotionsScrollRequest } from "src/app/types/list-promotions-scroll-request.type";

@Injectable({
    providedIn: "root"
})

export class PromotionsService extends PromotionBaseService {
    public async list(params: ListPromotionsScrollRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list", params, true);
    }

    public async testBook() {
        return await this.post("/test/book", null, true);
    }

    public async testBundle() {
        return await this.post("/test/bundle", null, true);
    }
}

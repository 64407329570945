import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogsConfirmActionComponent } from "src/app/dialogs/dialogs-confirm-action/dialogs-confirm-action.component";
import { BundleService } from "src/app/services/bundle/bundle.service";
import { FormBundleService } from "src/app/services/form/form-bundle.service";
import { PromotionService } from "src/app/services/promotion/promotion.service";
import { Bundle } from "src/app/types/bundle.type";
import { UpdateBundleRequest } from "src/app/types/update-bundle-request.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-bundles-edit",
    templateUrl: "./bundles-edit.component.html",
    styleUrls: ["./bundles-edit.component.scss"]
})

export class BundlesEditComponent {
    private pageLoaderIsActive: boolean = true;
    private editLoaderButtonIsActive: boolean = false;
    private deleteLoaderButtonIsActive: boolean = false;
    private promoteLoaderButtonIsActive: boolean = false;

    private hidePageLoader() {
        this.pageLoaderIsActive = false
    }

    private showEditButtonLoader() {
        this.editLoaderButtonIsActive = true;
    }

    private hideEditButtonLoader() {
        this.editLoaderButtonIsActive = false;
    }

    private showDeleteButtonLoader() {
        this.deleteLoaderButtonIsActive = true;
    }

    private showPromoteButtonLoader() {
        this.promoteLoaderButtonIsActive = true;
    }

    private async initCoverImage() {
        const xml = new XMLHttpRequest()

        xml.open("GET", "https://" + this.bucketName + ".s3.amazonaws.com/" + this.bundle.cover.src);

        xml.responseType = "blob";

        xml.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");

        xml.onload = () => {
            const blob = xml.response;

            const reader = new FileReader();

            reader.onloadend = (image: any) => {
                this.cover?.patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(blob)
        }

        xml.send()
    }

    private async initInternalsPreviewImage(index: number) {
        const xml = new XMLHttpRequest()

        xml.open("GET", "https://" + this.bucketName + ".s3.amazonaws.com/" + this.bundle.internalsPreview[index].src);

        xml.responseType = "blob";

        xml.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");

        xml.onload = () => {
            const blob = xml.response;

            const reader = new FileReader();

            reader.onloadend = (image: any) => {
                this.internalsPreview.controls[index].patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(blob)
        }

        xml.send()
    }

    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public editBundleForm: FormGroup;
    public id: string = "";
    public bundle!: Bundle;
    public coverData: any;
    public obj: any = {};

    public constructor(
        private route: ActivatedRoute,
        private formBundleService: FormBundleService,
        private bundleService: BundleService,
        private promoteService: PromotionService,
        private dialog: MatDialog,
        private router: Router
    ) {
        this.editBundleForm = this.formBundleService.edit;
    }

    public async ngOnInit() {
        this.id = this.route.snapshot.paramMap.get("id") || "";

        this.bundle = await this.bundleService.read(this.id)

        this.initCoverImage()

        this.editBundleForm.reset({
            baseInformations: this.bundle.baseInformations,
            seo: this.bundle.seo,
            cover: this.bundle.cover,
            downloadLink: this.bundle.downloadLink,
            status: this.bundle.status,
            price: this.bundle.price / 100,
            settings: this.bundle.settings,
            stripeData: this.bundle.stripeData
        })

        this.internalsPreview.clear()

        for (let i = 0; i < this.bundle.internalsPreview.length; i++) {
            const image: FormGroup = new FormBuilder().group({
                title: [ this.bundle.internalsPreview[i].title, [ Validators.required ] ],
                alt: [ this.bundle.internalsPreview[i].alt, [ Validators.required ] ],
                src: null,
                tempBase64: [ null, [ Validators.required ] ]
            })

            this.internalsPreview.push(image)

            this.initInternalsPreviewImage(i)
        }

        this.hidePageLoader()
    }

    public get price() {
        return this.editBundleForm.get("price")
    }

    public get downloadLink() {
        return this.editBundleForm.get("downloadLink")
    }

    public get cover() {
        return this.editBundleForm.get("cover")
    }

    public get seo() {
        return this.editBundleForm.get("seo")
    }

    public get baseInformations() {
        return this.editBundleForm.get("baseInformations")
    }

    public get status() {
        return this.editBundleForm.get("status")
    }

    public get settings() {
        return this.editBundleForm.get("settings")
    }

    public get stripeData() {
        return this.editBundleForm.get("stripeData")
    }

    public get internalsPreview(): FormArray {
        return this.editBundleForm.get("internalsPreview") as FormArray
    }

    public getCoverTempBase64() {
        return (this.editBundleForm.get("cover")?.value).tempBase64
    }

    public getInternalsPreviewTempBase64(index: number) {
        return (this.editBundleForm.get("internalsPreview")?.value)[index].tempBase64
    }

    public addPreviewClick() {
        const image: FormGroup = new FormBuilder().group({
            title: [ null, [ Validators.required ] ],
            alt: [ null, [ Validators.required ] ],
            src: null,
            tempBase64: [ null, [ Validators.required ] ]
        })

        this.internalsPreview.push(image)
    }

    public coverImageUploadChange(input: any) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (image: any) => {
                this.cover?.patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public internalsPreviewUploadChange(input: any, index: number) {
        if (input.files && input.files[0]) {
            const reader = new FileReader()

            reader.onload = (image: any) => {
                this.internalsPreview.controls[index].patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public getPageLoaderState(): boolean {
        return this.pageLoaderIsActive;
    }

    public getEditButtonLoaderState(): boolean {
        return this.editLoaderButtonIsActive;
    }

    public getDeleteButtonLoaderState(): boolean {
        return this.deleteLoaderButtonIsActive;
    }

    public getPromoteButtonLoaderState(): boolean {
        return this.promoteLoaderButtonIsActive;
    }

    public async editBundleButtonClick() {
        if ( ! this.formBundleService.validateForm(this.editBundleForm)) {
            return
        }

        this.showEditButtonLoader()

        const bundle: UpdateBundleRequest = {
            id: this.id,
            baseInformations: this.baseInformations?.value,
            cover: this.cover?.value,
            seo: this.seo?.value,
            internalsPreview: this.internalsPreview?.value,
            price: this.price?.value * 100,
            downloadLink: this.downloadLink?.value,
            status: this.status?.value,
            settings: this.settings?.value,
            stripeData: this.stripeData?.value
        }

        try {
            const updateBundle = await this.bundleService.update(bundle);

            this.bundle = updateBundle;

            this.hideEditButtonLoader();
        } catch(error) {
            console.log(error);
        }
    }

    public async promoteBookButtonClick() {
        this.showPromoteButtonLoader()

        await this.promoteService.create({
            settings: {
                book: false,
                bundle: true
            },
            bundleId: this.bundle.id,
            title: this.bundle.baseInformations.title
        })

        this.router.navigate(["/promotions"])
    }

    public async deleteBundleButtonClick() {
        this.dialog.open(DialogsConfirmActionComponent, {
            data: {
                title: "Delete bundle",
                message: "Delete this bundle?"
            },
            width: "512px"
        }).afterClosed().subscribe(async (result) => {
            if (result.data === "confirm") {
                this.showDeleteButtonLoader()

                await this.bundleService.delete({
                    id: this.bundle.id,
                    slug: this.bundle.baseInformations.slug,
                    settings: this.bundle.settings
                })

                this.router.navigate(["/bundles"])
            }
        })
    }
}

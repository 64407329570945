import { Injectable } from "@angular/core";
import { BundleBaseService } from "./bundle-base.service";
import { Bundle } from "src/app/types/bundle.type";
import { CreateBundleRequest } from "src/app/types/create-bundle-request.type";
import { UpdateBundleRequest } from "src/app/types/update-bundle-request.type";
import { DeleteBundleRequest } from "src/app/types/delete-bundle-request.type";

@Injectable({
    providedIn: "root"
})

export class BundleService extends BundleBaseService {
    public async create(params: CreateBundleRequest): Promise<Bundle> {
        return await this.post("/", params, true)
    }

    public async update(params: UpdateBundleRequest): Promise<Bundle> {
        return await this.put("/", params, true)
    }

    public async read(id: string): Promise<Bundle> {
        return await this.get("/" + id, null, true)
    }

    public async delete(params: DeleteBundleRequest) {
        return await this.del("/", params, true)
    }
}

<div class="shared-navbar">
    <div class="content">
        <h1>Administrator</h1>
        <ul *ngIf="admin">
            <li routerLink="/users" routerLinkActive="active">Users</li>
            <li routerLink="/contacts" routerLinkActive="active">Contacts</li>
            <li routerLink="/bundles" routerLinkActive="active">Bundles</li>
            <li routerLink="/books" routerLinkActive="active">Books</li>
            <li routerLink="/promotions" routerLinkActive="active">Promotions</li>
            <li>|</li>
            <li routerLink="/account" routerLinkActive="active">Account</li>
        </ul>
    </div>
</div>
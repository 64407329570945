<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Bundles > List</h1>
            <button routerLink="/bundles/create"><mat-icon>add</mat-icon>Create bundle</button>
        </div>
        <div *ngIf="getTableLoaderState(); then showBundlesLoader else hideBundlesLoader"></div>
        <ng-template #showBundlesLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #hideBundlesLoader>
            <div *ngIf="bundles.length > 0; then showBundles else bundlesNotFound"></div>
            <ng-template #showBundles>
                <table class="table">
                    <tr>
                        <th><div>Title</div></th>
                        <th><div>Creation date</div></th>
                        <th><div>Featured</div></th>
                        <th><div>Price</div></th>
                        <th><div>Status</div></th>
                        <th><div></div></th>
                    </tr>
                    <tr *ngFor="let bundle of bundles">
                        <td><strong>{{ bundle.baseInformations.title }}</strong></td>
                        <td>{{ bundle.createdAt | date }}</td>
                        <td>{{ bundle.settings.featured }}</td>
                        <td>{{ bundle.price / 100 | currency: "EUR" }}</td>
                        <td>
                            <div class="table__status--{{ bundle.status | lowercase }}">
                                {{ bundle.status }}
                            </div>
                        </td>
                        <td routerLink="/bundles/edit/{{ bundle.id }}">
                            <mat-icon>more_horiz</mat-icon>
                        </td>
                    </tr>
                </table>
            </ng-template>
            <ng-template #bundlesNotFound>
                <div class="not-found-container">Bundles not found</div>
            </ng-template>
        </ng-template>
    </div>
</div>
export const environment = {
    production: false,
    API_BASE_PATH: "admins-api.dev.coloringbookempire.com",
    IDENTITY_POOL_ID: "us-east-1:d2195774-1133-406c-b3bf-68c751e2ebed",
    IDENTITY_POOL_REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_vZF1Lxt7y",
    USER_POOL_CLIENT_ID: "79r5v2hdm6p8tgidu9iao585t6",
    USER_POOL_REGION: "us-east-1",
    WEBSITE_BUCKET_REGION: "eu-east-1",
    WEBSITE_BUCKET_NAME: "dev-coloring-book-empire-website"
}
import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

@Injectable({
    providedIn: "root"
})

export class FormBaseService {
    public matcher: FormErrorStateMatcher = new FormErrorStateMatcher();

    public validateForm(formGroup: UntypedFormGroup) {
        let valid: boolean = true;

        for (let controlName of Object.keys(formGroup.controls)) {
            if (formGroup.get(controlName)?.value != null) {
                let values = formGroup.get(controlName)?.value;

                for (let controlName2 of Object.keys(values)) {
                    if (formGroup.get(controlName + "." + controlName2)?.invalid) {
                        valid = false;

                        formGroup.get(controlName + "." + controlName2)?.markAsTouched(); 
                    }
                }
            } else {
                if (formGroup.get(controlName)?.invalid) {
                    valid = false;

                    formGroup.get(controlName)?.markAsTouched();
                }
            }
        }

        return valid;
    }

    public getError(formGroup: UntypedFormGroup, key: string, error: string) {
        if (formGroup.get(key)?.errors) {
            return formGroup.get(key)?.hasError(error);
        }

        return false;
    }
}

export class FormErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;

        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        )
    }
}

import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogsConfirmActionComponent } from "src/app/dialogs/dialogs-confirm-action/dialogs-confirm-action.component";
import { PromotionService } from "src/app/services/promotion/promotion.service";
import { Promotion } from "src/app/types/promotion.type";

@Component({
    selector: "app-promotions-edit",
    templateUrl: "./promotions-edit.component.html",
    styleUrls: ["./promotions-edit.component.scss"]
})

export class PromotionsEditComponent {
    private pageLoaderIsActive: boolean = true;
    private deleteLoaderButtonIsActive: boolean = false;

    private hidePageLoader() {
        this.pageLoaderIsActive = false
    }

    private showDeleteButtonLoader() {
        this.deleteLoaderButtonIsActive = true;
    }

    public id: string = "";
    public promotion!: Promotion;

    public constructor(
        private route: ActivatedRoute,
        private promotionService: PromotionService,
        private dialog: MatDialog,
        private router: Router
    ) { }

    public async ngOnInit() {
        this.id = this.route.snapshot.paramMap.get("id") || "";

        this.promotion = await this.promotionService.read(this.id)

        this.hidePageLoader()
    }

    public getPageLoaderState(): boolean {
        return this.pageLoaderIsActive;
    }

    public getDeleteButtonLoaderState(): boolean {
        return this.deleteLoaderButtonIsActive;
    }

    public async deletePromotionButtonClick() {
        this.dialog.open(DialogsConfirmActionComponent, {
            data: {
                title: "Test bundle promotion",
                message: "Test bundle promotion"
            },
            width: "512px"
        }).afterClosed().subscribe(async (result: any) => {
            if (result.data === "confirm") {
                this.showDeleteButtonLoader()

                await this.promotionService.delete({
                    id: this.promotion.id
                })

                this.router.navigate(["/promotions"])
            }
        })
    }
}

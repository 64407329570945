import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PromotionsRoutingModule } from "./promotions-routing.module";
import { PromotionsListComponent } from './promotions-list/promotions-list.component';
import { SharedModule } from "../shared/shared.module";
import { PromotionsEditComponent } from './promotions-edit/promotions-edit.component';

@NgModule({
    declarations: [
        PromotionsListComponent,
        PromotionsEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PromotionsRoutingModule
    ]
})

export class PromotionsModule { }

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PromotionsComponent } from "./promotions.component";
import { PromotionsListComponent } from "./promotions-list/promotions-list.component";
import { AuthGuard } from "../guards/auth.guard";
import { PromotionsEditComponent } from "./promotions-edit/promotions-edit.component";

const routes: Routes = [
    {
        path: "promotions",
        component: PromotionsComponent,
        children: [
            {
                path: "",
                component: PromotionsListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "list",
                component: PromotionsListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "edit/:id",
                component: PromotionsEditComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PromotionsRoutingModule { }

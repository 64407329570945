import { Injectable } from "@angular/core";
import { BookBaseService } from "./book-base.service";
import { Book } from "src/app/types/book.type";
import { CreateBookRequest } from "src/app/types/create-book-request.type";
import { DeleteBookRequest } from "src/app/types/delete-book-request.type";
import { UpdateBookRequest } from "src/app/types/update-book-request.type";

@Injectable({
    providedIn: "root"
})

export class BookService extends BookBaseService {
    public async create(params: CreateBookRequest): Promise<Book> {
        return await this.post("/", params, true)
    }

    public async read(id: string): Promise<Book> {
        return await this.get("/" + id, null, true)
    }

    public async update(params: UpdateBookRequest): Promise<Book> {
        return await this.put("/", params, true)
    }

    public async delete(params: DeleteBookRequest) {
        return await this.del("/", params, true)
    }
}

import { Component } from "@angular/core";
import { BundlesService } from "src/app/services/bundle/bundles.service";
import { Bundle } from "src/app/types/bundle.type";
import { ListBundlesScrollRequest } from "src/app/types/list-bundles-scroll-request.type";

@Component({
    selector: "app-bundles-list",
    templateUrl: "./bundles-list.component.html",
    styleUrls: ["./bundles-list.component.scss"]
})

export class BundlesListComponent {
    private tableLoaderIsActive: boolean = true;

    private hideTableLoader() {
        this.tableLoaderIsActive = false
    }

    public bundles: Bundle[] = [];

    public constructor(
        private bundlesService: BundlesService
    ) { }

    public async ngOnInit() {
        const params: ListBundlesScrollRequest = {
            limit: 100
        }

        this.bundles = (await this.bundlesService.list(params)).data;

        this.hideTableLoader();
    }

    public getTableLoaderState(): boolean {
        return this.tableLoaderIsActive;
    }
}

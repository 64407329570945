import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FormAuthService } from "src/app/services/form/form-auth.service";
import { UtilsDialogsService } from "src/app/services/utils/utils-dialogs.service";

@Component({
    selector: "app-new-password",
    templateUrl: "./new-password.component.html",
    styleUrls: ["./new-password.component.scss"]
})

export class NewPasswordComponent {
    private newPasswordButtonLoaderIsActive: boolean = false;
    private showNewPasswordPassword: boolean = false;

    private showSigninButtonLoader() {
        this.newPasswordButtonLoaderIsActive = true;
    }

    private hideSigninButtonLoader() {
        this.newPasswordButtonLoaderIsActive = false;
    }

    public newPasswordForm: FormGroup;

    public constructor(
        private formAuthService: FormAuthService,
        private auth: AuthService,
        private router: Router,
        private utilsDialogsService: UtilsDialogsService
    ) {
        this.newPasswordForm = this.formAuthService.newPassword;
    }

    public ngOnInit() {
        this.newPasswordForm.reset();
    }

    public get currentPassword() {
        return this.newPasswordForm.get("currentPassword");
    }

    public get newPassword() {
        return this.newPasswordForm.get("newPassword");
    }

    public get confirmPassword() {
        return this.newPasswordForm.get("confirmPassword");
    }

    public getNewPasswordButtonLoaderState(): boolean {
        return this.newPasswordButtonLoaderIsActive;
    }

    public showPasswordTypeToggle() {
        this.showNewPasswordPassword = !this.showNewPasswordPassword;
    }

    public getNewPasswordPasswordType(): "text" | "password" {
        if (this.showNewPasswordPassword) {
            return "text";
        }

        return "password";
    }

    public async newPasswordButtonClick() {
        if ( ! this.formAuthService.validateForm(this.newPasswordForm)) {
            return;
        }

        this.showSigninButtonLoader();

        let username: string = localStorage.getItem("username") || "";
        let currentPassword: string = this.currentPassword?.value;
        let newPassword: string = this.newPassword?.value;

        let data = await this.auth.completeNewPassword(username, currentPassword, newPassword)

        /*

        let data = await this.auth.signIn(email, password);

        if (data.response.code === "UserNotFoundException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("User does not exist.");

            return;
        }

        if (data.response.code === "NotAuthorizedException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("Incorrect username or password.");

            return;
        }

        this.router.navigate(['contacts']);

        */
    }
}

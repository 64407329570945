import { Component } from "@angular/core";
import { BooksService } from "src/app/services/book/books.service";
import { Book } from "src/app/types/book.type";
import { ListBooksScrollRequest } from "src/app/types/list-books-scroll-request.type";

@Component({
    selector: "app-books-list",
    templateUrl: "./books-list.component.html",
    styleUrls: ["./books-list.component.scss"]
})

export class BooksListComponent {
    private tableLoaderIsActive: boolean = true;

    private hideTableLoader() {
        this.tableLoaderIsActive = false
    }

    public books: Book[] = [];

    public constructor(
        private booksService: BooksService
    ) { }

    public async ngOnInit() {
        const params: ListBooksScrollRequest = {
            limit: 100
        }

        this.books = (await this.booksService.list(params)).data;

        this.hideTableLoader();
    }

    public getTableLoaderState(): boolean {
        return this.tableLoaderIsActive;
    }
}

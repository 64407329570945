import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";
import { ContactsComponent } from "./contacts.component";
import { ContactsListComponent } from "./contacts-list/contacts-list.component";
import { ContactsCreateComponent } from "./contacts-create/contacts-create.component";
import { ContactsEditComponent } from "./contacts-edit/contacts-edit.component";
import { ContactsReadComponent } from "./contacts-read/contacts-read.component";

const routes: Routes = [
    {
        path: "contacts",
        component: ContactsComponent,
        children: [
            {
                path: "",
                component: ContactsListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "list",
                component: ContactsListComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "create",
                component: ContactsCreateComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "edit",
                component: ContactsEditComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "read",
                component: ContactsReadComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ContactsRoutingModule { }

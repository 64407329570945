import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export enum AuthResponseState {
  error,
  success,
}

@Injectable({
  providedIn: "root"
})

export class AuthService {
    constructor() {}

    private responseFormatting(state: AuthResponseState, response: any): any {
        return {
            state: state,
            response: response
        }
    }

    private success(response: any): any {
        return this.responseFormatting(AuthResponseState.success, response);
    }

    private error(error: any): any {
        return this.responseFormatting(AuthResponseState.error, error);
    }

    public async isAuthenticated(): Promise<boolean> {
        try {
            await this.getAuthenticatedUser();

            return true;
        } catch (error) {
            return false;
        }
    }

    public async getAuthenticatedUser() {
        return await Auth.currentAuthenticatedUser();
    }

    public async signIn(username: string, password: string): Promise<any> {
        try {
            let response = await Auth.signIn(username, password);

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async signUp(user: any): Promise<any> {
        try {
            let response = await Auth.signUp(user);

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async signOut() {
        try {
            let response = await Auth.signOut();

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async completeNewPassword(username: string, currentPassword: string, newPassword: string) {
        try {
            const user = await Auth.signIn(username, currentPassword);

            const response = await Auth.completeNewPassword(
                user,
                newPassword,
            )

            return this.success(response);
        } catch(error) {
            return this.error(error);
        }
    }
}

<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="section">
        <div class="content">
            <div class="page-title">
                <h1>Account</h1>
            </div>
        </div>
    </div>
</div>

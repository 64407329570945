<div class="section">
    <div class="content">
        <div class="page-title">
            <h1>Contacts > List</h1>
        </div>
        <div *ngIf="getTableLoaderState(); then showContactsLoader else hideContactsLoader"></div>
        <ng-template #showContactsLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #hideContactsLoader>
            <div *ngIf="contacts.length > 0; then showContacts else contactsNotFound"></div>
            <ng-template #showContacts>
                <table class="table">
                    <tr>
                        <th><div>Name</div></th>
                        <th><div>Email</div></th>
                        <th><div>Book subscription</div></th>
                        <th><div>Bundle subscription</div></th>
                        <th><div>Creation date</div></th>
                    </tr>
                    <tr *ngFor="let contact of contacts">
                        <td>{{ contact.firstname }} {{ contact.lastname }}</td>
                        <td><strong>{{ contact.email }}</strong></td>
                        <td>{{ contact.settings.books }}</td>
                        <td>{{ contact.settings.bundles }}</td>
                        <td>{{ contact.createdAt | date }}</td>
                    </tr>
                </table>
            </ng-template>
            <ng-template #contactsNotFound>
                <div class="not-found-container">Contacts not found</div>
            </ng-template>
        </ng-template>
    </div>
</div>

import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BundleService } from "src/app/services/bundle/bundle.service";
import { FormBundleService } from "src/app/services/form/form-bundle.service";
import { BundleStatus } from "src/app/types/bundle-status.type";
import { CreateBundleRequest } from "src/app/types/create-bundle-request.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-bundles-create",
    templateUrl: "./bundles-create.component.html",
    styleUrls: ["./bundles-create.component.scss"]
})

export class BundlesCreateComponent {
    private createLoaderButtonIsActive: boolean = false;

    private showCreateButtonLoader() {
        this.createLoaderButtonIsActive = true;
    }

    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;
    public createBundleForm: FormGroup;

    public ngOnInit() {
        this.createBundleForm.reset({
            status: BundleStatus.Draft
        })

        this.internalsPreview.clear()
    }

    public constructor(
        private formBundleService: FormBundleService,
        private bundleService: BundleService,
        private router: Router
    ) {
        this.createBundleForm = this.formBundleService.create
    }

    public get baseInformations() {
        return this.createBundleForm.get("baseInformations")
    }

    public get downloadLink() {
        return this.createBundleForm.get("downloadLink")
    }

    public get seo() {
        return this.createBundleForm.get("seo")
    }

    public get cover() {
        return this.createBundleForm.get("cover")
    }

    public get status() {
        return this.createBundleForm.get("status")
    }

    public get price() {
        return this.createBundleForm.get("price")
    }

    public get settings() {
        return this.createBundleForm.get("settings")
    }

    public get internalsPreview(): FormArray {
        return this.createBundleForm.get("internalsPreview") as FormArray
    }

    public getCoverTempBase64() {
        return (this.createBundleForm.get("cover")?.value).tempBase64
    }

    public getInternalsPreviewTempBase64(index: number) {
        return (this.createBundleForm.get("internalsPreview")?.value)[index].tempBase64
    }

    public addPreviewClick() {
        const image: FormGroup = new FormBuilder().group({
            title: [ null, [ Validators.required ] ],
            alt: [ null, [ Validators.required ] ],
            src: null,
            tempBase64: [ null, [ Validators.required ] ]
        })

        this.internalsPreview.push(image)
    }

    public coverImageUploadChange(input: any) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (image: any) => {
                this.cover?.patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public internalsPreviewUploadChange(input: any, index: number) {
        if (input.files && input.files[0]) {
            const reader = new FileReader()

            reader.onload = (image: any) => {
                this.internalsPreview.controls[index].patchValue({
                    tempBase64: image.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    public getCreateButtonLoaderState(): boolean {
        return this.createLoaderButtonIsActive;
    }

    public async createBundleButtonClick() {
        if ( ! this.formBundleService.validateForm(this.createBundleForm)) {
            return;
        }

        this.showCreateButtonLoader()

        const bundle: CreateBundleRequest = {
            baseInformations: this.baseInformations?.value,
            seo: this.seo?.value,
            cover: this.cover?.value,
            internalsPreview: this.internalsPreview?.value,
            downloadLink: this.downloadLink?.value,
            price: this.price?.value * 100,
            status: this.status?.value,
            settings: this.settings?.value,
            stripeData: {
                priceId: "",
                productId: "",
                paymentLinkId: "",
                paymentLinkUrl: ""
            }
        }

        try {
            await this.bundleService.create(bundle);

            this.router.navigate(["/bundles/list"])
        } catch(error) {
            console.log(error);
        }
    }
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: "root"
})

export class UnauthGuard {
    constructor(
        public auth: AuthService,
        public router: Router
    ) {}

    public async canActivate(): Promise<boolean> {
        const isAuthenticated = await this.auth.isAuthenticated();

        if ( ! isAuthenticated) {
            return true;
        }

        this.router.navigate(["contacts"]);

        return false;
    }
}
